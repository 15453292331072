import React, { FC } from "react";
import { CircularProgress as MuiCircularProgress, Fade } from "@mui/material";
import { useTheme } from "styled-components";
import { ILoaderProps } from "./Loader.types";
// import { TButtonColor } from "components/form/SaveButton/Button.types";
import { LoaderContent } from "./Loader.style";

const Loader: FC<ILoaderProps> = ({
  color = "black",
  size = 40,
  animationDuration = 1000,
  isTable = false,
  fullWidth = false,
  ...props
}) => {
  const theme: any = useTheme();

  const switchColor = (color: string): string => {
    if (color) {
      return theme[color]?.main;
    }
    return "primary";
  };

  return (
    <LoaderContent fullWidth={fullWidth}>
      <div className={!isTable ? "loader-page" : ""}>
        <MuiCircularProgress
          sx={{
            color: switchColor(color),
            animationDuration: `${animationDuration}ms`,
          }}
          size={size}
          {...props}
        />
      </div>
    </LoaderContent>
  );
};

export default Loader;
