import styled from "styled-components";
import get from "lodash.get";

export const DrawerBtnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => get(theme, "white.main")};
  position: absolute;
  left: 0px;
  right: 0px;
  padding: 24px;
  bottom: 0px;
  button {
    width: 48%;
  }
`;

export const CustomButton = styled.button`
  border-radius: 12px;
  background: ${({ theme }) => get(theme, "success.main")};
  padding: 16px 25px;
  height: 48px;
  outline: none;
  border: none;
  color: ${({ theme }) => get(theme, "white.main")};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  position: relative;
  transition: all ease 0.5s;
  overflow: hidden;
  cursor: pointer;
  svg {
    width: 25px;
    height: 25px;
    path {
      transition: all 0.1s ease-in-out;
      fill: ${({ theme }) => get(theme, "white.main")};
    }
  }
  /* &:disabled {
    background: rgba(112, 177, 124, 0.5);
  } */
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 17px 25px;
  outline: none;
  color: ${({ theme }) => get(theme, "black.light")};
  background: ${({ theme }) => get(theme, "secondary.light")};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: none;
  transition: all 0.2s ease-in-out;
  height: 48px;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  border-radius: 6px;
  background: ${({ theme }) => get(theme, "white.main")} !important;
  padding: 9.7px 12px;
  outline: none;
  color: ${({ theme }) => get(theme, "error.main")} !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  box-sizing: border-box;
  border: none;
  &:hover {
    background: ${({ theme }) => get(theme, "error.light")} !important;
  }
`;

export const TextArea = styled.textarea<any>`
  border-radius: 12px;
  height: 200px;
  border: none;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.error.main : theme.secondary.light)};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.secondary.light};
  width: 100% !important;
  color: ${({ theme }) => theme.black.main};
  padding: 15px 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
`;

export const AddEditAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  svg {
    margin: 0 8px;
    cursor: pointer;
    &:first-of-type {
      /* width: 14px; */
      /* height: 18px; */
    }
    &:last-of-type {
      /* width: 18px;
      height: 16px; */
    }
  }
  .media {
    svg {
      /* width: 18px;
      height: 18px; */
    }
  }
  .message {
    svg {
      /* width: 24px !important;
      height: 24px !important; */
    }
  }
`;
