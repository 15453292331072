const ModeratorIcon = () => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.456 15.978C20.3526 16.2761 20.136 16.5215 19.8531 16.6611C19.5701 16.8007 19.2436 16.8233 18.9441 16.7239C18.6447 16.6245 18.3964 16.4111 18.2531 16.1301C18.1098 15.849 18.0829 15.5227 18.1784 15.222C18.5942 13.9544 18.804 12.6284 18.8 11.2944C18.8 5.9472 15.4928 2.4 11 2.4C6.50717 2.4 3.19997 5.9472 3.19997 11.2944C3.19997 12.6624 3.41357 13.9908 3.82157 15.222C3.91701 15.5227 3.89017 15.849 3.74685 16.1301C3.60353 16.4111 3.35527 16.6245 3.05582 16.7239C2.75637 16.8233 2.42982 16.8007 2.14686 16.6611C1.8639 16.5215 1.64731 16.2761 1.54397 15.978C1.04719 14.4666 0.795998 12.8853 0.799973 11.2944C0.799973 4.6584 5.14277 0 11 0C16.8572 0 21.2 4.6584 21.2 11.2944C21.2 12.9192 20.9456 14.502 20.456 15.9768V15.978Z"
        fill="white"
      />
      <path
        d="M6.17239 18.336C5.22136 18.3177 4.31622 17.9237 3.65472 17.2402C2.99322 16.5567 2.62913 15.6391 2.64199 14.688V14.6616C2.64839 14.1906 2.74782 13.7256 2.93456 13.2932C3.12131 12.8608 3.39169 12.4696 3.73015 12.1421C4.06861 11.8146 4.46847 11.5572 4.90676 11.3847C5.34504 11.2122 5.81309 11.1281 6.28399 11.1372C6.60079 11.1435 6.90224 11.2749 7.12256 11.5026C7.34287 11.7303 7.46417 12.036 7.45999 12.3528L7.39519 17.1528C7.39301 17.3109 7.3596 17.467 7.29688 17.6122C7.23417 17.7573 7.14339 17.8886 7.02976 17.9986C6.91612 18.1085 6.78188 18.1949 6.63475 18.2529C6.48761 18.3108 6.33048 18.339 6.17239 18.336ZM15.9068 11.0952C16.8578 11.1135 17.763 11.5074 18.4245 12.191C19.086 12.8745 19.4501 13.7921 19.4372 14.7432V14.7696C19.4309 15.2389 19.3323 15.7024 19.1469 16.1335C18.9615 16.5647 18.693 16.9552 18.3567 17.2825C18.0204 17.6099 17.6229 17.8679 17.1869 18.0416C16.7509 18.2154 16.2849 18.3015 15.8156 18.2952H15.7964C15.4792 18.2892 15.1772 18.1578 14.9566 17.9298C14.736 17.7018 14.6147 17.3956 14.6192 17.0784L14.684 12.2784C14.6862 12.1203 14.7196 11.9642 14.7823 11.819C14.845 11.6739 14.9358 11.5425 15.0494 11.4326C15.1631 11.3226 15.2973 11.2362 15.4444 11.1783C15.5916 11.1204 15.7487 11.0922 15.9068 11.0952Z"
        fill="white"
      />
    </svg>
  );
};

export default ModeratorIcon;
