import React from "react";

const SectionIcon = () => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.74343 6.75373H9.17058C10.3276 6.75373 10.914 6.19403 10.914 5.06716V1.68657C10.914 0.552239 10.3276 0 9.17058 0H1.74343C0.586354 0 0 0.552239 0 1.68657V5.06716C0 6.19403 0.586354 6.75373 1.74343 6.75373ZM14.9638 12.9478H20.2566C21.4136 12.9478 22 12.3881 22 11.2612V2.85821C22 1.72388 21.4136 1.16418 20.2566 1.16418H14.9638C13.8067 1.16418 13.2203 1.72388 13.2203 2.85821V11.2612C13.2203 12.3881 13.8067 12.9478 14.9638 12.9478ZM3.61976 14H10.0306C11.1955 14 11.7818 13.4478 11.7818 12.3134V9.90298C11.7818 8.76866 11.1955 8.20895 10.0306 8.20895H3.61976C2.45487 8.20895 1.86851 8.76866 1.86851 9.90298V12.3134C1.86851 13.4478 2.45487 14 3.61976 14Z"
        fill="white"
      />
    </svg>
  );
};

export default SectionIcon;
