import styled from "styled-components";

export const LoaderContent = styled.div<any>`
  .loader-page {
    width: 100%;
    height: ${(props) => (!props.fullWidth ? "calc(100vh - 80px)" : "100vh")};
    background: ${({ theme }) => theme.colors.bg.mainBg};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
`;
