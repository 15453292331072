import styled from "styled-components";

export const StyledInput = styled.input`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.secondary.light};
  background-color: ${({ theme }) => theme.secondary.light};
  box-sizing: border-box;
  width: 100%;
  padding: 10px 14px;
  height: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  outline: none;
  transition: all 300ms ease-out;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  &.error {
    border-color: ${({ theme }) => theme.error.main};
  }
`;
