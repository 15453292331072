import get from "lodash.get";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { REQUEST_STATUS, useRequest } from "../../../hooks";
import browserStorage from "../../../services/storage/browserStorage";

export const useLogin = () => {
  const navigate = useNavigate();
  const formStore = useForm();
  const { handleSubmit } = formStore;

  const [loginClient, loginData, loginStatus] = useRequest();

  const login = handleSubmit((data: any) => {
    loginClient.post("employee/login", data);
  });

  useEffect(() => {
    if (
      loginStatus === REQUEST_STATUS.success &&
      get(loginData, "data.token")
    ) {
      browserStorage.set("token", get(loginData, "data.token"));
      browserStorage.set(
        "isSuperAdmin",
        loginData?.data?.isSuperAdmin ? "_p93*&^(" : false
      );
      navigate("/company");
    }
  }, [loginStatus]);

  return {
    state: { formStore },
    actions: {
      login,
    },
  };
};
