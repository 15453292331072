import { ISideItemsChildrenT } from "../../Sidebar.types";
import { isActive } from "../isActive";

export const isSubMenuActive = (
  children: ISideItemsChildrenT[],
  pathname: string,
  collapse: boolean
) => {
  return (
    collapse && children.some((child) => isActive(child.insidePaths, pathname))
  );
};
