import { lazy } from "react";
import { IRoute } from "./Routes.types";

const Company = lazy(() => import("../../pages/company/container"));
const Driver = lazy(() => import("../../pages/driver/container"));
const Employee = lazy(() => import("../../pages/employee/container"));

export const routes: IRoute[] = [
  {
    key: "notFound",
    name: "company",
    isPrivate: true,
    path: "/company",
    element: <Company />,
  },
  {
    key: "notFound",
    name: "driver",
    isPrivate: true,
    path: "/driver",
    element: <Driver />,
  },
  {
    key: "notFound",
    name: "driver",
    isPrivate: true,
    path: "/driver/:companyId",
    element: <Driver />,
  },
  {
    key: "admin",
    name: "employee",
    isPrivate: true,
    path: "/employee",
    element: <Employee />,
  },
];
