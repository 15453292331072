import React, { Suspense, useState } from "react";
import { Route, Routes as Switch } from "react-router-dom";

import { StyledApp } from "../../App.style";
import { Sidebar } from "../../components";
import Loader from "../../components/common/Loader/Loader";
import RoleManager from "../../services/roleManagement";
import { routes } from "./Routes.constants";

const Routes = () => {
  const [hasAccess] = RoleManager();
  const [collapse, setCollapse] = useState<boolean>(false);

  return (
    <>
      <Suspense fallback={<Loader fullWidth size={60} />}>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <Sidebar collapse={collapse} setCollapse={setCollapse} />
          <StyledApp collapse={collapse}>
            <Suspense fallback={<Loader />}>
              <div style={{ width: "100%", height: "100%" }}>
                <Switch>
                  {routes.map((route, index) => (
                    <React.Fragment key={index}>
                      {hasAccess(route.key) && <Route {...route} />}
                    </React.Fragment>
                  ))}
                </Switch>
              </div>
            </Suspense>
          </StyledApp>
        </div>
      </Suspense>
    </>
  );
};

export default Routes;
