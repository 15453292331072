import { FC } from "react";
import { MenuItem, TextField } from "@mui/material";
import { AutoComplateStyled } from "./Select.style";
import { ISelectProps } from "./Select.types";
import Label from "../Label/Label";
import { Error } from "../../index";
import { Controller } from "react-hook-form";
import { currencyFormatter, debounce } from "../../../services";
import TickIcon from "../../../assets/TickIcon";

const Select: FC<ISelectProps> = ({
  label = "",
  options = [],
  error,
  name = "",
  className = "",
  handleInputChange,
  control,
  rules = { required: false },
  defaultValue,
  dataKey = "name",
  dataKey2,
  dataKey3,
  defaultInputValue = "",
  multiple = false,
  onChangeSelect,
  placeholder = "",
  optional,
  disabled = false,
  isClearable,
  isSearchable = false,
  ...props
}) => {
  const onInputChange = debounce(handleInputChange, 400);

  return (
    <div className={className}>
      {label && <Label label={label} />}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, ...field } }) => {
          return (
            <AutoComplateStyled
              multiple={multiple}
              id="select"
              options={options}
              defaultValue={defaultValue}
              className={!!error ? "error" : ""}
              getOptionLabel={(option: any) => option[dataKey] || ""}
              loadingText="Loading..."
              noOptionsText="Data not found"
              disabled={disabled}
              onChange={(event: any, dataItem: any) => {
                onChange(dataItem);
                if (onChangeSelect) {
                  onChangeSelect(dataItem);
                }
              }}
              renderOption={(props: any, option: any) => {
                const selectedIds =
                  field.value?.length && field.value?.map((e: any) => e._id);

                return (
                  <MenuItem
                    {...props}
                    key={option?.value}
                    style={{ position: "relative" }}
                  >
                    {option[dataKey]}
                    {selectedIds?.length &&
                      selectedIds?.includes(option?._id) && (
                        <span
                          style={{
                            position: "absolute",
                            right: "60px",
                          }}
                          className="tick-icon"
                        >
                          <TickIcon />
                        </span>
                      )}
                  </MenuItem>
                );
              }}
              // isOptionEqualToValue={(option: any, value: any) =>
              //   option[dataKey] === value[dataKey]
              // }
              renderInput={(prop: any) => (
                <TextField
                  {...prop}
                  label=""
                  placeholder={placeholder}
                  onChange={(e) =>
                    handleInputChange && onInputChange(e.target.value)
                  }
                />
              )}
              {...props}
              value={field.value}
            />
          );
        }}
      />
      {/* {!!error && <Error message={"Majburiy qator"} />} */}
    </div>
  );
};

export default Select;
