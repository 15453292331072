import styled from "styled-components";

export const LoginStyle = styled.div`
  display: flex;
  height: 100vh;
  .title {
    margin-bottom: 56px;
  }
  .form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    .form {
      max-width: 300px;
      width: 100%;
      input {
        border: 1px solid #ddd;
      }
    }
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: ${({ theme }) => theme.white.main};
    color: #fff;
    font-size: 50px;
    img {
      max-width: 300px;
    }
  }
  .login-btn {
    width: 100%;
  }
`;
