export const theme = {
  primary: {
    main: "#0087E9",
  },
  secondary: {
    main: "#D9D9D9",
    light: "#F5F5F5",
    medium: "#BDBDBD",
  },
  black: {
    main: "#232323",
    dark: "#000",
    light: "#454545",
  },
  white: {
    main: "#fff",
  },
  rgba: {
    white: "rgba(255, 255, 255, 0.1)",
    light: "rgba(255, 255, 255, 0.2)",
  },
  success: {
    main: "#70B17C",
  },
  error: {
    main: "#FF0000",
    light: "#EA7474",
  },

  colors: {
    bg: {
      mainBg: "#F8F8F8",
      whiteBg: "#ffffff",
      blue: "#4CE5B1",
      darkBlue: "#1da1d1",
      mainLightBg: "#F6F6F9",
      hoverBlue: "#E6FBF4",
    },
    text: {
      lightBlack: "#313949",
      black: "#000000",
      red: "red",
      blue: "#00B2F5",
      white: "#ffffff",
    },
    color: {
      blue: "#00B2F5",
      black: "#000000",
      lightBlack: "#333333",
      red: "red",
      lightGrey: "#EDF1FF",
    },
    border: {
      grey: "rgba(49, 57, 73, 0.1)",
      red: "red",
      lightBlack: "#ccc",
    },
    button: {
      blue: "#4CE5B1",
      red: "red",
      lightBlue: "#E6FBF4",
    },
  },
};
