import { LoginStyle } from "./Login.style";
import { CustomButton } from "../../../components/style/style";
import { Input } from "../../../components";
import { useContext } from "react";
import { LoginContext } from "../context";
import Logo from "../../../assets/Logo.jpg";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const {
    state: { formStore },
    actions: { login },
  } = useContext(LoginContext);

  const {
    register,
    formState: { errors },
  } = formStore;

  return (
    <LoginStyle>
      <div className="form-wrapper">
        <form className="form" onSubmit={login}>
          <h2 className="page-title title">{t("login.welcome")}</h2>
          <Input
            label="Login"
            params={{
              ...register("login", {
                required: true,
              }),
            }}
            error={errors.login}
            className="mb-4"
          />
          <Input
            label={t("login.password")}
            params={{
              ...register("password", {
                required: true,
              }),
              type: "password",
            }}
            error={errors.password}
            className="mb-4"
          />
          <CustomButton className="login-btn">{t("login.login")}</CustomButton>
        </form>
      </div>
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
    </LoginStyle>
  );
};

export default Login;
