import React, { Suspense, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  Route,
  Routes as Switch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Routes from "./routes/container/Routes";
import { LightTheme } from "./styles/theme/LightTheme";
import "bootstrap/dist/css/bootstrap.min.css";
import { hasToken } from "./routes/utils/hasToken/hasToken";
import CommonProvider from "./context/CommonProvider";
import Login from "./pages/login/container";

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      return navigate("/company");
    }
    if (location.pathname === "/login") {
      if (hasToken()) {
        navigate(-1);
      }
    }
  }, []);

  return (
    <CommonProvider>
      <Suspense fallback="">
        <ThemeProvider theme={LightTheme}>
          <Switch>
            <Route path="*" element={<Routes />} />
            <Route path="/login" element={<Login />} />
          </Switch>
          <Toaster containerStyle={{ zIndex: "100000" }} />
        </ThemeProvider>
      </Suspense>
    </CommonProvider>
  );
};

export default App;
