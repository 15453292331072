import styled from "styled-components";

export const ChangeLanguageStyled = styled.div`
  color: black;
  #demo-positioned-button {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: none !important;
    color: #fff;
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.rgba.light};
    font-weight: 500;
    font-size: 16px;
    border-radius: 16px;
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 1060px) {
    #demo-positioned-button {
      .lang-text {
        display: none;
      }
      span {
        img {
          margin-right: 0px;
          margin-top: 0px;
        }
      }
    }
  }
`;
