import { useContext } from "react";
import get from "lodash.get";
import { CommonContext } from "../../context";
import browserStorage from "../storage/browserStorage";

const RoleManager = () => {
  const {
    state: {
      roleState: { roleData },
    },
  } = useContext(CommonContext);
  const isSuperAdmin = browserStorage.get("isSuperAdmin");
  const hasAccess = (name: string) => {
    try {
      return name === "admin" &&
        isSuperAdmin === "_p93*&^("
        ? true
        : name === "notFound"
        ? true
        : get(roleData, "data")?.[name];
    } catch (err) {
      return false;
    }
  };

  return [hasAccess];
};

export default RoleManager;
