import { ISideItemsT } from "./Sidebar.types";
import ModeratorIcon from "./assets/ModeratorIcon";
import SectionIcon from "./assets/SectionIcon";
import TaskIcon from "./assets/TaskIcon";

export const SidebarList: ISideItemsT[] = [
  {
    id: "notFound",
    path: "/company",
    icon: <SectionIcon />,
    title: "company",
    insidePaths: ["/company"],
  },
  // {
  //   id: "notFound",
  //   path: "/driver",
  //   icon: <TaskIcon />,
  //   title: "driver",
  //   insidePaths: ["/driver"],
  // },
  {
    id: "admin",
    path: "/employee",
    icon: <ModeratorIcon />,
    title: "moderator",
    insidePaths: ["/employee"],
  },
];
