import { Autocomplete } from "@mui/material";
import styled from "styled-components";

export const AutoComplateStyled = styled(Autocomplete)<any>`
  .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
    background: ${({ theme }) => theme.secondary.light};
    border-radius: 12px;
    .MuiOutlinedInput-root.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      padding: 0;
      .MuiOutlinedInput-input.MuiInputBase-input {
        width: 100% !important;
        padding: 15px;
        font-size: 14px;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.secondary.light} !important;
      border-radius: 12px;
      padding: 15px;
    }
  }
  &.error {
    .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.error.main} !important;
      }
    }
  }
`;
